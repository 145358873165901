import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
var token = "d09abf1c-4d8c-4a1c-b577-69ebea61dc8c";

Vue.prototype.axios = axios
axios.defaults.baseURL = 'https://eh.aigpco.com'
axios.interceptors.request.use(config => {
  // config 就是 axios 的请求信息
  config.headers.token = localStorage.getItem('token')
  // 继续运行
  return config
})
axios.interceptors.response.use(res => {
  return res
},err=>{
  // res 服务器返回的信息
  // 如果登录过期返回登录页面、成功继续
  if (err.response.data.code == 401) {
    router.push('login')
  }
})
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
